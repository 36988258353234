<template>
  <div class="storeCheckBox">
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="商户名称：">
        <el-input size="small" placeholder="请输入商户名称" v-model="searchStoreName"></el-input>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input size="small" placeholder="请输入联系人" v-model="searchName"></el-input>
      </el-form-item>
      <el-form-item label="联系人电话：">
        <el-input size="small" placeholder="请输入联系人电话" v-model="searchTel"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="">导出</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="checkInStatus">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in statusArr" :key="index" :label="item.title + '(' + item.count + ')'" :name="item.status"></el-tab-pane>
      </el-tabs>
    </div>
    <el-table :data="dataList" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center" label="店铺名称" width="100">
        <template v-slot="{ row }">
          {{ row.store_name == '' ? '--' : row.store_name }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="merchant_name" label="商户名称" width="200"></el-table-column>
      <el-table-column align="center" prop="main_project" label="主营项目" width="100"></el-table-column>
      <el-table-column align="center" prop="contact_name" label="联系人" width="100"></el-table-column>
      <el-table-column align="center" prop="contact_phone" label="联系电话" width="150"></el-table-column>
      <el-table-column align="center" prop="store_phone" label="门店电话" width="150"></el-table-column>
      <el-table-column align="center" prop="store_site" label="门店地址"></el-table-column>
      <el-table-column align="center" label="商品状态" width="100">
        <template v-slot="{ row }">
          <p :style="{ color: row.status == 1 ? '#fdc47d' : row.status == 2 ? '#409EFF' : '#ff7978' }">
            {{ row.status == 1 ? '待审核' : row.status == 2 ? '已通过' : '未通过' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="date" label="操作">
        <template v-slot="{ row }">
          <el-button type="text" @click="lookDetail(row, 1)">查看</el-button>
          <el-button type="text" v-if="row.status == 1" @click="examine(row, 1)">通过</el-button>
          <el-button type="text" v-if="row.status == 1" @click="examine(row, 2)">驳回</el-button>
          <el-button type="text" v-if="row.status == 3" @click="lookDetail(row, 4)">审核详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    <!-- 查看详情弹框 -->
    <el-dialog title="查看详情" :visible.sync="dialogVisibleDetail" width="52%">
      <el-form label-width="120px" class="demo-ruleForm dialogBox">
        <el-form-item label="商户名称：">
          {{ lookOne.merchant_name }}
        </el-form-item>
        <el-form-item label="主营项目：">
          {{ lookOne.main_project }}
        </el-form-item>
        <el-form-item label="简单介绍：">
          {{ lookOne.intro }}
        </el-form-item>
        <el-form-item label="联系人：">
          {{ lookOne.contact_name }}
        </el-form-item>
        <el-form-item label="联系电话：">
          {{ lookOne.contact_phone }}
        </el-form-item>
        <el-form-item label="门店电话：">
          {{ lookOne.store_phone }}
        </el-form-item>
        <el-form-item label="门店地址：">
          {{ lookOne.store_site }}
        </el-form-item>
        <el-form-item label="营业执照：">
          <!-- <img style="width: 400px; height: 300px" :src="lookOne.business_license" alt="" /> -->
          <el-image :z-index="9999" style="width: 400px; height: 300px" :src="lookOne.business_license" :preview-src-list="[lookOne.business_license]"></el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看审核详情 -->
    <el-dialog title="审核详情" :visible.sync="dialogVisibleReject" width="52%">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="审核结果：">驳回</el-form-item>
        <el-form-item label="驳回原因：">
          <el-input style="width: 500px" type="textarea" :rows="3" disabled v-model="lookOne.remark"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 审核驳回 -->
    <el-dialog title="审核" :visible.sync="rejectDialog" width="40%">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="审核结果：">驳回</el-form-item>
        <el-form-item label="驳回原因：">
          <el-input style="width: 500px" type="textarea" :rows="3" v-model="rejectTxt"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialog = false">取 消</el-button>
        <el-button type="primary" @click="rejectSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      rejectDialog: false,
      dialogVisibleReject: false,
      dialogVisibleDetail: false,
      page: 1,
      pageNum: 10,
      total: 0,
      activeName: 1,
      searchStoreName: null,
      searchName: null,
      searchTel: null,
      statusArr: [],
      dataList: [],
      lookOne: {},
      rejectTxt: null,
    };
  },
  created() {
    this.getCheckStatus();
    this.getList();
  },
  methods: {
    lookDetail(row, style) {
      this.lookOne = row;
      if (style == 1) {
        this.dialogVisibleDetail = true;
      } else if (style == 4) {
        this.dialogVisibleReject = true;
      }
    },
    search() {
      this.getCheckStatus();
      this.getList();
    },
    // 清除搜索的数据
    clearSearch() {
      this.searchStoreName = null;
      this.searchName = null;
      this.searchTel = null;
      this.activeName = 1;
      this.getCheckStatus();
      this.getList();
    },
    // 分页操作
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
    },
    // 审核函数
    examine(row, style) {
      let that = this;
      if (style == 1) {
        this.$confirm('确认通过审核！！！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            that.$axios
              .post(that.$api.store.SettledupStatus, {
                id: row.id,
                status: 2,
              })
              .then(res => {
                if (res.code == 0) {
                  that.$message.success('审核成功');
                  this.getCheckStatus();
                  this.getList();
                } else {
                  that.$message.error('审核失败');
                }
              });
          })
          .catch();
      } else if (style == 2) {
        this.lookOne = row;
        this.rejectDialog = true;
      }
    },
    // 确认驳回
    rejectSure() {
      if (this.rejectTxt == null) {
        this.$message.warning('请填写驳回原因！！');
        return;
      }
      let that = this;
      this.$axios
        .post(that.$api.store.SettledupStatus, {
          id: that.lookOne.id,
          status: 3,
          remark: that.rejectTxt,
        })
        .then(res => {
          if (res.code == 0) {
            that.$message.success('驳回成功');
            this.rejectDialog = false;
            this.getCheckStatus();
            this.getList();
          } else {
            that.$message.error('驳回失败');
          }
        });
    },
    // 获取表格数据
    getList() {
      let that = this;
      this.$axios
        .post(this.$api.store.StoreSettled, {
          page: that.page,
          rows: that.pageNum,
          status: that.activeName,
          merchant_name: that.searchStoreName != null ? that.searchStoreName : null,
          contact_name: that.searchName != null ? that.searchName : null,
          contact_phone: that.searchTel != null ? that.searchTel : null,
        })
        .then(res => {
          if (res.code == 0) {
            that.dataList = res.result.list;
            that.total = res.result.total_number;
          }
        });
    },
    // 获取状态数组
    getCheckStatus() {
      let that = this;
      this.$axios
        .post(this.$api.store.checkInStatus, {
          merchant_name: that.searchStoreName != null ? that.searchStoreName : null,
          contact_name: that.searchName != null ? that.searchName : null,
          contact_phone: that.searchTel != null ? that.searchTel : null,
        })
        .then(res => {
          if (res.code == 0) {
            that.statusArr = res.result;
          } else {
            that.$message.error('系统错误');
          }
        });
    },
    handleClick(item) {
      this.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.storeCheckBox {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
    /deep/ .el-table__body-wrapper {
      height: calc(100% - 48px);
    }
  }
  .dialogBox {
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
